import ApolloClient from "apollo-boost";
import fetch from "isomorphic-fetch";
import { getUser } from "./auth";

export const apolloClient = new ApolloClient(
  {
    fetch,
    uri: "/.netlify/functions/fauna",
    request: (operation) =>
    {
      const lawchestUser = getUser();

      if (lawchestUser && lawchestUser.token)
      {
        operation.setContext(
          {
            headers:
            {
              authentication: lawchestUser.token
            }
          }
        );
      }
    }
  }
);