// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-index-js": () => import("./../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-login-js": () => import("./../src/pages/admin/login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-admin-logout-js": () => import("./../src/pages/admin/logout.js" /* webpackChunkName: "component---src-pages-admin-logout-js" */),
  "component---src-pages-admin-profile-js": () => import("./../src/pages/admin/profile.js" /* webpackChunkName: "component---src-pages-admin-profile-js" */),
  "component---src-pages-admin-tools-index-js": () => import("./../src/pages/admin/tools/index.js" /* webpackChunkName: "component---src-pages-admin-tools-index-js" */),
  "component---src-pages-admin-tools-penaltyunits-index-js": () => import("./../src/pages/admin/tools/penaltyunits/index.js" /* webpackChunkName: "component---src-pages-admin-tools-penaltyunits-index-js" */),
  "component---src-pages-admin-tools-penaltyunits-jurisdiction-index-js": () => import("./../src/pages/admin/tools/penaltyunits/jurisdiction/index.js" /* webpackChunkName: "component---src-pages-admin-tools-penaltyunits-jurisdiction-index-js" */),
  "component---src-pages-admin-tools-penaltyunits-jurisdiction-legislation-js": () => import("./../src/pages/admin/tools/penaltyunits/jurisdiction/legislation.js" /* webpackChunkName: "component---src-pages-admin-tools-penaltyunits-jurisdiction-legislation-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tools-index-js": () => import("./../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-penaltyunits-index-js": () => import("./../src/pages/tools/penaltyunits/index.js" /* webpackChunkName: "component---src-pages-tools-penaltyunits-index-js" */),
  "component---src-pages-tools-penaltyunits-result-js": () => import("./../src/pages/tools/penaltyunits/result.js" /* webpackChunkName: "component---src-pages-tools-penaltyunits-result-js" */),
  "component---src-pages-tools-presentencedetention-index-js": () => import("./../src/pages/tools/presentencedetention/index.js" /* webpackChunkName: "component---src-pages-tools-presentencedetention-index-js" */),
  "component---src-pages-tools-suggest-index-js": () => import("./../src/pages/tools/suggest/index.js" /* webpackChunkName: "component---src-pages-tools-suggest-index-js" */),
  "component---src-pages-tools-suggest-result-js": () => import("./../src/pages/tools/suggest/result.js" /* webpackChunkName: "component---src-pages-tools-suggest-result-js" */),
  "component---src-pages-tools-widgets-penaltyunits-index-js": () => import("./../src/pages/tools/widgets/penaltyunits/index.js" /* webpackChunkName: "component---src-pages-tools-widgets-penaltyunits-index-js" */),
  "component---src-pages-tools-widgets-penaltyunits-result-js": () => import("./../src/pages/tools/widgets/penaltyunits/result.js" /* webpackChunkName: "component---src-pages-tools-widgets-penaltyunits-result-js" */),
  "component---src-pages-tools-widgets-presentencedetention-index-js": () => import("./../src/pages/tools/widgets/presentencedetention/index.js" /* webpackChunkName: "component---src-pages-tools-widgets-presentencedetention-index-js" */)
}

