export const isBrowser = () =>
{
  return typeof window !== "undefined";
};

export const getUser = () =>
{
  return isBrowser() &&
    window.localStorage.getItem("lawchestUser") ?
      JSON.parse(window.localStorage.getItem("lawchestUser"))
      : {};
};

export const setUser = user =>
{
  if (user.token)
  {
    return window.localStorage.setItem("lawchestUser", JSON.stringify(user));
  }
  else
  {
    user.token = getUser().token;
    return window.localStorage.setItem("lawchestUser", JSON.stringify(user));
  }
};

export const isLoggedIn = () =>
{
  const user = getUser();

  return !!user.token;
};

export const logout = callback =>
{
  window.localStorage.setItem("lawchestUser", JSON.stringify({}));

  if (callback)
  {
    callback();
  }
};