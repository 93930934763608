require("./src/css/style.css");
const React = require("react")
const { ApolloProvider } = require("@apollo/react-hooks");
const { apolloClient } = require("./src/utils/apolloClient");

exports.wrapRootElement = ({ element }) =>
{
  return (
    <ApolloProvider
      client = { apolloClient }
    >
      { element }
    </ApolloProvider>
  );
};